.root {
  display: flex;
}

.body{
  overflow: hidden;
}
.content {
  display: flex;
  flex: 1;
  height: 100%; /* Set height to 100% to fill the viewport */
}

.left {
  flex: 1;
  background-color: black; /* Set the background color to black */
}
.left-div {
  width: 107.28px;
  height: 271.42px;
  top: 0.29px;
  left: 0.72px;
  gap: 0px;
  background-color: black;
  margin-left: 200px;
}
.builder-text {
  font-family: Noto Sans;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 648px;
  height: 60px;
  gap: 0px;
  margin-top: 0px;
}

.future-text {
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}


.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: white; /* Set the background color to white */
}


.bgImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qssLogo {
  width: 50%;
}



.qssTechnosoftImage {
  width: 310px;
  height: 135px;
}

.paper {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.submit {
  margin-top: 20px;
}

.footer {
  margin-top: 20px;
  text-align: center;
}

.wrongCred{
  color: red;
}
