/* Dashboard.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.dashboard-container {
  padding: 20px;
  background-color: #E5E7EB;
}

.dashboard-header {
  height: 86px;
  padding: 16px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
  border-radius: 8px 8px 8px 8px;
  border-right: 1px solid transparent;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04); /* Added box-shadow */
  opacity: 1;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-container div div input {
  padding: 5px;
}

.search-input {
  height: 36px;
  border-radius: 20px;
  border: 2px solid rgb(36 17 159 / 83%);
  padding: 0 15px;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #FF5722;
}

.search-button,
.action-button {
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  text-decoration: none;
}

.search-button {
  background-color: rgb(36 17 159 / 83%);
  color: white;
  margin-left: 10px;
}

.action-button {
  background-color: white;
  color: rgb(36 17 159 / 83%);
}

.table-container {
  margin-top: 20px;
  background-color: white;
  border-radius: 8px 8px 8px 8px !important;
}

.table-header{
  font-weight: bold !important;
}

.resume-item {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.resume-name {
  font-weight: bold;
}

.resume-actions button {
  margin-left: 5px;
  color: #4CAF50;
}

.action-delete{
  margin-bottom: 14px !important;
  min-width: 25px !important;
  padding: 5px !important;
}

.action-download{
  margin-bottom: 14px !important;
  min-width: 25px !important;
  padding: 5px !important;
}
.no-border {
border: none !important;
}
.header_content {
margin-bottom: 15px;
display: flex;
justify-content: center;
}

.button {
background-color: #ffffff;
height: 120px;
border: 2px solid rgba(255, 255, 255, 1); /* Border style */
opacity: 1; /* Change opacity to 1 to make the buttons visible */
display: flex;
justify-content: center;
align-items: center; /* Center the content vertically */
}

.action-button {
text-decoration: none;
color: inherit;
font-size: 16px; /* Adjust font size if needed */
}

.button.with-avatar {
  display: flex;
  align-items: center;
  border-radius: 8px 8px 8px 8px;
}


.table-pagination {
  height: 52px; /* Hug (52px)px */
  padding: 12px 20px; /* Adjust padding as needed */
  gap: 0px;
  border: 1px solid transparent;
  border-top: 1px solid rgba(210, 214, 219, 1); /* Border style */
  justify-content: space-between;
  opacity: 1; /* Adjusted opacity */
}

.header_content {
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  background-color: #ffffff;
  height: 120px;
  border: 2px solid rgba(255, 255, 255, 1); /* Border style */
  opacity: 1; /* Change opacity to 1 to make the buttons visible */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the content vertically */
}

.action-button {
  text-decoration: none;
  color: inherit;
  font-size: 16px; /* Adjust font size if needed */
}



.button.with-avatar .avatar {
  width: 52px;
  height: 52px;
  margin-right: 10px;
  opacity: 1;
}

.button.with-avatar .button-text {
  font-size: 25px;
  font-weight: 600;
  line-height: 30.26px;
  text-align: left;
  color: #fff;
}

.button.with-avatar .action-button {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-text-avatar {
  color:#94979C !important;
font-size: 18px;
font-weight: 400;
line-height: 21.6px;
text-align: left;

}

.iconButton{
  margin-right: 10px !important;
  font-size: 16px !important;
  color: #0B3B5B !important;
  min-width: 32px !important;
  height: 32px;
  border-radius: 50% !important;
  border-bottom: 2px solid #0B3B5B !important;
  border-top: 2px solid #0B3B5B !important;
  border-right: 2px solid #0B3B5B !important;
  border-left: 2px solid #0B3B5B !important;
}

.tooltip-delete{
  background-color: red !important;
}