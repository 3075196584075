.navbar {
  overflow: hidden;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-heading {
  display: flex;
  align-items: center;
  color: white;
  margin: 0;
  width: 90%;
}

.navbar-logo {
  width: 120px; /* Adjust size as needed */
  margin-right: 10px; /* Add some space between logo and title */
}

.navbar-title {
  flex-grow: 1; /* Expand to fill remaining space */
  text-align: center; /* Center align title */
  color: #EC3636
}

.logout-button {
  color: #EC3636 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}

.resume-dashboard-heading {
  text-align: center; /* Center align resume dashboard heading */
}
