/* AddResume.css */

.add-resume-container {
  padding: 20px;
  background-color: #f5f5f5;
  width: 60%; /* Set the width of the form */
  margin: 0 auto; /* Center the form horizontally */
  border-radius: 10px; /* Add border radius to the form */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow to the form */
}

.add-resume-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-resume-title{
  padding: 20px;
}

.form-section {
  margin-bottom: 20px;
}

.form-field {
  width: 50%; /* Set the width of the text fields */
}

.project-section {
  margin-top: 20px;
}

.project-field {
  margin-bottom: 20px;
}

.project-button {
  margin-top: 10px;
}

.submit-button {
  margin-top: 20px;
}

.project-card {
  background-color: white;
  margin-top: 10px;
}

.profile-toaster div{
  background-color: green;
}

.back-button{
  background-color: #1976d2;
  color: white;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  text-decoration: none;
}